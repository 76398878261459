import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function CourseFormatSection({ children }) {
  return (
    <div className="medium-light-gray-bg py-xl-5 py-4" id="course_format">
      {children}
    </div>
  );
}

function CourseStructure({
  startDate,
  endDate,
  timeAndDay,
  image,
  mobileImage,
}) {
  return (
    <div id="structure">
      <Container fluid="lg" className="py-4 py-xl-5">
        <Row className="justify-content-between align-items-center">
          <Col xl={6} sm={12} className="mb-4 pb-3 mb-xl-0 pb-xl-0">
            {image ? (
              <img
                src={image}
                className="img-fluid d-none d-xl-block"
                alt="meridian university course structure image"
              />
            ) : (
              <img
                src="https://web-cdn.meridianuniversity.edu/site-content-images/oe-course-course-structure.webp"
                className="img-fluid d-none d-xl-block"
                alt="beautiful lake and mountain skyline"
              />
            )}
            {mobileImage ? (
              <img
                src={mobileImage}
                className="img-fluid d-block d-xl-none"
                alt="meridian university course structure image"
              />
            ) : (
              <img
                src="https://web-cdn.meridianuniversity.edu/site-content-images/oe-course-course-structure-mobile.webp"
                className="img-fluid d-block d-xl-none"
                alt="beautiful lake and mountain skyline"
              />
            )}
          </Col>
          <Col xl={5} sm={12}>
            <Row>
              <Col xs={12}>
                <h2 className="mb-4">Course Structure</h2>
                <ol>
                  <li>
                    Seven-week online course from {startDate} through {endDate}.
                  </li>
                  <li>
                    Six live 75-minute Zoom sessions with faculty held{" "}
                    <strong>{timeAndDay} PT (Pacific Time)</strong> and recorded
                    for participants who cannot join every session live.{" "}
                    <strong>
                      Note: There is no live class during Week One.
                    </strong>
                  </li>
                  <li>
                    Resources, networking, and emergent community outcomes.
                  </li>
                  <li>
                    Content and collaboration via Meridian's social learning
                    platform.
                  </li>
                </ol>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

function CourseScheduleContainer({ children, image, mobileImage }) {
  return (
    <div id="schedule">
      <Container fluid="lg" className="py-4 py-xl-5">
        <Row className="justify-content-between align-items-center">
          <Col
            xl={{ span: 6, order: "last" }}
            sm={12}
            className="mb-4 pb-3 mb-xl-0 pb-xl-0"
          >
            {image ? (
              <img
                src={image}
                className="img-fluid d-none d-xl-block"
                alt="meridian university course schedule image"
              />
            ) : (
              <img
                src="https://web-cdn.meridianuniversity.edu/site-content-images/oe-course-course-schedule.webp"
                className="img-fluid d-none d-xl-block"
                alt="beautiful desert plateau skyline"
              />
            )}
            {mobileImage ? (
              <img
                src={mobileImage}
                className="img-fluid d-block d-xl-none"
                alt="meridian university course structure image"
              />
            ) : (
              <img
                src="https://web-cdn.meridianuniversity.edu/site-content-images/oe-course-course-schedule-mobile.webp"
                className="img-fluid d-block d-xl-none"
                alt="beautiful desert plateau skyline"
              />
            )}
          </Col>
          <Col xl={5} sm={12}>
            <Row>
              <Col xs={12}>
                <h2 className="mb-4">Course Schedule</h2>
                <Row>{children}</Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

function CourseScheduleWeek({
  date,
  time,
  children,
  xlOrder,
  lgOrder,
  mdOrder,
  smOrder,
}) {
  return (
    <Col
      xl={{ span: 6, order: `${xlOrder}` }}
      lg={3}
      md={{ span: 4, order: `${mdOrder}` }}
      sm={{ span: 6, order: `${xlOrder}` }}
    >
      <div className="week white-bg">
        <div className="week-info">
          <span className="icon">
            <img
              src="https://web-cdn.meridianuniversity.edu/site-content-images/oe-course-calendar.svg"
              className="img-fluid"
              alt="calendar icon"
            />
          </span>
          <span className="date">{date}</span>
          {time ? (
            <>
              <span className="line">|</span>
              <span className="icon">
                <img
                  src="https://web-cdn.meridianuniversity.edu/site-content-images/oe-course-clock.svg"
                  className="img-fluid"
                  alt="calendar icon"
                />
              </span>
              <span className="time">{time}</span>
            </>
          ) : (
            <></>
          )}
        </div>
        <h4>{children}</h4>
      </div>
    </Col>
  );
}

function CourseFeesAndAccred() {
  return (
    <div id="accredAndFinAid">
      <Container fluid="lg" className="py-lg-5 py-4">
        <Row>
          <Col xs={12} lg className="pe-lg-4 mb-4 mb-lg-0">
            <h3 className="mb-4">Course Fees</h3>
            <p>$250</p>
            <p>
              - Live video classes
              <br />- Access to recordings on course platform
              <br />- Course platform community and resources
              <br />- Participant launch of ongoing Community of Practice after
              the course
            </p>
          </Col>
          <Col xs={12} lg className="ps-lg-4">
            <h3 className="mb-4">Accreditation</h3>
            <p>
              Meridian University is accredited by the Western Association of
              Schools and Colleges (WASC) - a higher education accreditor
              recognized by the United States Department of Education. WASC is
              also the accreditor for Stanford University, UCLA, and the
              University of California at Berkeley.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export {
  CourseFormatSection,
  CourseScheduleContainer,
  CourseStructure,
  CourseScheduleWeek,
  CourseFeesAndAccred,
};
