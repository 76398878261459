import * as React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import StripeRegisterForm from '../forms/stripeRegisterForm'
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'

import '../styles/stripeRegister.scss';

const stripePromise = loadStripe('pk_live_zxPVztFFG5bxRLdq5EUB3qqx');

function CourseRegistration(pros) {
 
    return (
        <div id="courseRegistration" className="py-4 py-xl-5">
            <Container fluid="lg"  className="py-4 py-xl-5">
                <Row className="justify-content-center align-items-center">
                    <Col xl={5} md={10}xs={12} className="mb-3 mb-xl-0">
                        <h2 className="pb-4 mb-1">Register</h2>
                        <p className="reg-text pb-3 mb-0">Register here to join a community of engaged practitioners for this unique online course experience. Pre-registration below is required to attend and space is limited. Registrations will be processed on a first-come-first-served basis and to ensure a quality experience for participants, last minute registration is not guaranteed.</p>
                        <p className="reg-text pb-4 mb-1">Meridian coursework students should register for this course through the Services App. Meridian continuation students have the option to register as public participants without academic credit. Continuation students and Meridian alumni are  eligible for a 50% discount by contacting <a href="mailto:ctl@meridianuniversity.edu" style={{ color: 'white' }}>ctl@meridianuniversity.edu</a>. </p>
                    </Col>
                    <Col xl={{ span: 6, offset: 1 }} md={10} xs={12}>
                        <Elements stripe={stripePromise}>
                            <StripeRegisterForm form={pros.form} submitCampaignID = {pros.submitCampaignID} submitType = {pros.submitType} standardPriceId = {pros.standardPriceId} academicPriceId= {pros.academicPriceId}/>
                        </Elements>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

export default CourseRegistration