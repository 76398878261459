import React from "react"
import { Container, Row, Col } from "react-bootstrap";
import { useGetFaculty } from "../../hooks/get-course-faculty";
import CollapseContent from "../helpers/collapseContent";
import { MDXRenderer } from "gatsby-plugin-mdx";

import "./onlineCourses.scss"

function CourseFaculty({course, children, sort, showMore, type}){
    
    const { nodes } = useGetFaculty();
    const findCourseFaculty = nodes.filter(n => n.frontmatter.tags.includes(course));
    const sortValue = sort;
    const courseFacultyCount = findCourseFaculty.length

    if(type === "growing"){ 
        return (
            <div id="faculty">
                <Container fluid="lg">
                    <div className="mt-4 mt-md-5 px-2 px-lg-0">
                        <Row className="py-md-5 py-4 single justify-content-center">
                            { findCourseFaculty.map((faculty, index) => {
                                return (
                                    <Col xs={12} lg={10} key={index++} >
                                        <div className="faculty-bio">
                                            <Row className="align-items-center mb-n5">
                                                <Col xs={4} lg={3} className="ms-4">
                                                    <img src={faculty.frontmatter.headshot} className="img-fluid img-rounded" alt={`${faculty.frontmatter.title} Headshot`} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="bio-box">
                                                    <h2 className="mb-3">{faculty.frontmatter.title}</h2>
                                                    { showMore === "true" ? 
                                                        <CollapseContent index={index++} content={faculty.body} openText="Learn More" closeText="Show Less" />
                                                        :
                                                        <div index={index++}>
                                                            <MDXRenderer>
                                                                {faculty.body}
                                                            </MDXRenderer>
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                )
                            })}
                        </Row>
                    </div>
                </Container>
            </div>
        )   
    } else {
        return (
        <div id="faculty">
            <Container fluid="lg">
                <div className="my-md-5 my-4 px-2 px-lg-0">
                    { courseFacultyCount > 1 ?
                        <>
                            <Row className="py-md-5 py-4 double">
                                <Col xs={12}>
                                    <h2>{children}</h2>
                                    <hr/>
                                </Col>
                                { sortValue ? 
                                    findCourseFaculty.sort((a, b) => (a.frontmatter[sortValue] > b.frontmatter[sortValue]) ? 1 : -1).map((faculty, index) => {
                                        return(
                                            <Col lg={6} xs={12} className="mb-4">
                                                <div className="faculty-bio">
                                                    <Row className="align-items-center mb-n5">
                                                        <Col xs={4} className="ms-4">
                                                            <img src={faculty.frontmatter.headshot} className="img-fluid img-rounded" alt={`${faculty.frontmatter.title} Headshot`} />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="bio-box">
                                                            <h2>{faculty.frontmatter.title}</h2>
                                                            <CollapseContent index={index++} content={faculty.body} openText="Learn More" closeText="Show Less" />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        )
                                    })
                                    :
                                    findCourseFaculty.map((faculty, index) => {
                                        return(
                                            <Col lg={6} xs={12} key={index++} className="mb-4">
                                                <div className="faculty-bio">
                                                    <Row className="align-items-center mb-n5">
                                                        <Col xs={4} className="ms-4">
                                                            <img src={faculty.frontmatter.headshot} className="img-fluid img-rounded" alt={`${faculty.frontmatter.title} Headshot`} />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="bio-box">
                                                            <h2>{faculty.frontmatter.title}</h2>
                                                            <CollapseContent index={index++} content={faculty.body} openText="Learn More" closeText="Show Less" />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </>
                        :
                        <>
                            <Row className="py-md-5 py-4 single">
                                <Col xs={12}>
                                    <h2>{children}</h2>
                                    <hr/>
                                </Col>
                                { findCourseFaculty.map((faculty, index) => {
                                    return (
                                        <Col xs={12} key={index++} >
                                            <div className="faculty-bio">
                                                <Row className="align-items-center justify-content-center d-none d-lg-flex">
                                                    <Col xs={4} md={3} lg={2} className="me-n5">
                                                        <img src={faculty.frontmatter.headshot} className="img-fluid img-rounded" alt={`${faculty.frontmatter.title} Headshot`} />
                                                    </Col>
                                                    <Col className="bio-box">
                                                        <h2>{faculty.frontmatter.title}</h2>
                                                        { showMore === "true" ? 
                                                        <CollapseContent index={index++} content={faculty.body} openText="Learn More" closeText="Show Less" />
                                                        :
                                                        <div index={index++}>
                                                            <MDXRenderer>
                                                                {faculty.body}
                                                            </MDXRenderer>
                                                        </div>
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center mb-n5 d-lg-none">
                                                    <Col xs={4} className="ms-4">
                                                        <img src={faculty.frontmatter.headshot} className="img-fluid img-rounded" alt={`${faculty.frontmatter.title} Headshot`} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="bio-box d-lg-none">
                                                        <h2>{faculty.frontmatter.title}</h2>
                                                        { showMore === "true" ? 
                                                        <CollapseContent index={index++} content={faculty.body} openText="Learn More" closeText="Show Less" />
                                                        :
                                                        <div index={index++}>
                                                            <MDXRenderer>
                                                                {faculty.body}
                                                            </MDXRenderer>
                                                        </div>
                                                        }
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    )
                                    }
                                )}
                            </Row>
                        </>
                    }
                </div>
            </Container>
        </div>
    )
}
}

export default CourseFaculty