import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from "@mdx-js/react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Ratio from 'react-bootstrap/Ratio'
import SSRProvider from 'react-bootstrap/SSRProvider'
import ReactPlayer from 'react-player'

import CourseFaculty from '../components/online-courses/courseFaculty'
import CourseFAQ from '../components/online-courses/courseFAQ'
import { CourseFormatSection, CourseStructure, CourseScheduleContainer, CourseScheduleWeek, CourseFeesAndAccred } from '../components/online-courses/courseFormat'
import { CourseIntro, CourseIntroImage, CourseIntroQuote } from '../components/online-courses/courseIntro'
import { CourseModuleSection, CourseModule } from '../components/online-courses/courseModules'
import CourseRegistration from '../components/online-courses/courseRegistration'
import DefaultHeader from '../components/default-header'
import Footer from '../components/footer'
import GdprPanel from '../components/gdpr'
import Navigation from '../components/navigation'
import PageQuote from '../components/quote'
import Seo from '../components/seo'
import SubNavigation from '../components/subNav'
import NewsletterSignup from '../components/newsletterSignUp'

export default function DegreePage({ data }) {

    const post = data.mdx

    const shortcodes = {
        Container,
        Row,
        Col,
        PageQuote,
        Ratio,
        CourseFaculty,
        CourseFAQ,
        CourseFeesAndAccred,
        CourseFormatSection,
        CourseIntro,
        CourseIntroImage,
        CourseIntroQuote,
        CourseModuleSection,
        CourseModule,
        CourseRegistration,
        CourseScheduleContainer,
        CourseScheduleWeek,
        CourseStructure,
        ReactPlayer,
      }

    return (      
        <>  
        <SSRProvider>
            <Navigation />
            <DefaultHeader headline={post.frontmatter.headline} heroBackground={post.frontmatter.heroBackground} heroMobileBackground={post.frontmatter.heroMobileBackground} subHeadline={post.frontmatter.subheadline} cta="true"  />
            <SubNavigation type="new" subNav={post.frontmatter.subNav} /> 
            <MDXProvider components={shortcodes}>
                <MDXRenderer>{post.body}</MDXRenderer>
            </MDXProvider>      
            <Footer />
            <GdprPanel />
            <NewsletterSignup />
        </SSRProvider>
        </>
    )
}

export const Head = ({data}) => (
    <Seo 
        title={data.mdx.frontmatter.metadata.Title} 
        description={data.mdx.frontmatter.metadata.Description} 
        pathname={data.mdx.frontmatter.metadata.Link}
        ogImage={data.mdx.frontmatter.metadata.ogImage}
        cardImage={data.mdx.frontmatter.metadata.cardImage} 
        pageType="course"
    />
)

export const query = graphql`
    query($slug: String!) {
        mdx(slug: { eq: $slug }) {
            frontmatter {
                heroBackground
                heroMobileBackground
                subheadline
                headline
                title
                headerType
                metadata {
                    Description
                    Link
                    Title
                    ogImage
                    cardImage
                }
                menuType
                subNav {
                    text
                    url
                }
            }
            body
        }
    }   
`